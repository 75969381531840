import axios from 'axios';
import { FC, useState, createContext, useContext, useEffect } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

type Firm = {
  firmName: string;
  displayName: string;
  address: string;
  registration: string;
  phone: string[];
  website: string;
  logo: string;
  description: string;
};

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  firm: Firm;
};

type AuthContext = {
  loggedIn: boolean;
  accessToken: string;
  user: User;
  logOut: () => void;
  logIn: (accessToken: string) => Promise<void>;
  refreshUserInfo: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const AuthContext = createContext<AuthContext>({} as AuthContext);

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User>({} as User);
  const [accessToken, setAccessToken] = useState<string>(
    localStorage.getItem('accessToken') || ''
  );
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const verifyUser = async (accessToken: string) => {
    return axios
      .get(`${process.env.REACT_APP_API}/auth/getMyInfo`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
      .then((response) => {
        setLoggedIn(true);
        setUser(response.data);
      })
      .catch(() => {
        setLoggedIn(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshUserInfo = () => {
    return verifyUser(accessToken);
  };

  const logOut = () => {
    setLoggedIn(false);
    setAccessToken('');
    localStorage.removeItem('accessToken');
    setUser({} as User);
  };

  const logIn = (accessToken: string) => {
    setAccessToken(accessToken);
    localStorage.setItem('accessToken', accessToken);
    return verifyUser(accessToken);
  };

  useEffect(() => {
    if (accessToken) {
      verifyUser(accessToken);
    } else {
      setLoggedIn(false);
      setLoading(false);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ loggedIn, accessToken, user, logOut, logIn, refreshUserInfo }}
    >
      {loading ? <SuspenseLoader /> : children}
    </AuthContext.Provider>
  );
};
