import { useRoutes } from 'react-router-dom';
import { routes, publicRoutes } from 'src/router';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { useAuth } from './contexts/AuthContext';

function App() {
  const { loggedIn } = useAuth();
  const content = useRoutes(loggedIn ? routes : publicRoutes);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
